.Footer {
    /* height: 28px; */
    width: 100%;
    /* position: fixed; */
    bottom: 0;
    left: 0;
    color: white;
    /* background-color: #000000; */
    background: transparent;
    display: block; /* flex */
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    padding-bottom: 6px;
    padding-top: 6px;
    box-sizing: border-box;
    z-index: 90;
    font-size: 12px;

    text-transform: uppercase;
    font-family: "Futura Condensed PT Medium", sans-serif;
    letter-spacing: 0px;
    color: white;
}

.Footer nav {
    height: 100%;
}

.Logo {
    height: 80%;
}

.Social {
    width: 100%;
}

.Social img {
    width: 30px;
    margin-left: 3px;
    margin-right: 3px;
}

.Social a {
    filter: brightness(1);
    transition: all 200ms ease;
}

.Social a:hover {
    filter: brightness(2);
    transition: all 200ms ease;
}


@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
    .Footer {
        position: relative;
    }
}

/* iPhone X landscape */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : landscape) {
    .Footer {
        position: relative;
    }
}

@media (max-height: 470px) {
    .Footer {
        position: relative;
    }
}
