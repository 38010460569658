.Logo {
    color: white;
    /* background-color: black; */
    background: transparent;
    padding: 1px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 36px;
    font-weight: bold;
}

.Logo img {
    height: 100%;
}

@media (max-width: 750px) {
    .Logo {
        font-size: 24px;
    }
}
