@font-face {
    font-family: 'Trajan Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Trajan Pro Regular'), url('assets/fonts/trajan-pro-2.woff') format('woff');
}

@font-face {
    font-family: 'Futura Condensed PT Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Condensed PT Medium'), url('assets/fonts/futura-condensed-pt-medium-589e44ed1e3a5.woff') format('woff');
}

@font-face {
    font-family: 'Futura PT Light';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Light'), url('assets/fonts/futura-pt-light-589a6e187563a.woff') format('woff');
}

/*
@font-face {
    font-family: 'La Gioconda';
    font-style: normal;
    font-weight: normal;
    src: local('La Gioconda'), url('assets/fonts/la-gioconda-tt.woff2') format('woff');
}
*/

body {
    margin: 0;
    padding: 0;
    /* font-family: "Avenir Next","Helvetica Neue",Helvetica,Arial,sans-serif; */
    background-color: rgb(3, 130, 206);

    /* text-transform: uppercase; */
    /* font-family: "Trajan Pro Regular", serif; */
    font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0px;
    color: white;
}

/* unvisited link */
a:link {
    color: white;
}

/* visited link */
a:visited {
    color: white;
}

/* mouse over link */
a:hover {
    color: lightblue;
}

/* selected link */
a:active {
    color: white;
}

.background {
    position: fixed;
    overflow: visible;
    top: 0;
    right: 0;
    left: 0;
    height: 110%;
    width: 100%;
    z-index: -1;
    background-position: 50% 50%;
    background-size: cover;
    background-color: rgb(3, 130, 206);
    background-image: url(assets/images/bg-qc-main@1x.jpg);
    background-image: -webkit-image-set(url(assets/images/bg-qc-main@1x.jpg) 1x, url(assets/images/bg-qc-main@1x.jpg) 2x);
}