#content {
    box-shadow: 0px 0px 20px 1px rgb(1, 31, 65);
    /* height: 800px; */
    background-color: rgb(3, 130, 206);
    /* margin-left: 0px;
    margin-right: 0px; */
    margin: auto;
    width: 960px;
}

#masthead {
    width: 960px;
    height: 80px;
    margin: 0px auto;
    padding: 0px;
    position: relative;
    z-index: 2;
    background: none repeat scroll 0% 0% transparent;
    text-align: center;
}

#masthead #nav {
    background: url(./assets/images/qc-top-nav.png) no-repeat scroll 0px 0px transparent;
    width: 960px;
    height: 90px;
    padding: 0px;
    margin: 0px;
    position: relative;
    left: 0px;
    z-index: 100;
}

#logo {
    display: block;
    width: 155px;
    height: 75px;
    background: url(./assets/images/qc-logo.png) no-repeat scroll 2px -15px transparent;
    text-indent: -9999px;
    line-height: 0;
    position: absolute;
    top: 0px;
    left: 5px;
}