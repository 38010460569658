.about {
    padding-bottom: 40px;
}

.heroCopy {
    width: 600px;
    margin: auto;
    padding-bottom: 10px;
    text-align: left;
}

.johnCopy {
    width: 600px;
    margin: auto;
    padding-bottom: 10px;
    text-align: left;
}

.andrewCopy {
    width: 600px;
    margin: auto;
    padding-bottom: 10px;
    text-align: left;
}

.aboutEmmys {
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
    width: 400px;
    margin: auto;
}

.aboutEmmys h1 {
    text-align: center;
}