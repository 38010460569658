.NavigationItem {
    color: white;
    text-decoration: none;
    margin: 0;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.NavigationItem a {
    color: white;
    text-decoration: none;
    height: 100%;
    width: 100%;
    padding: 3px 10px;
    border-bottom: 1px solid transparent;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    /* background-color: #000000; */
    background: transparent;
    border-bottom: 1px solid #FFFFFF;
    color: white;
}

/*
.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    color: white;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: #40A4C8;
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }

    .NavigationItem a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }

    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        background-color: #000000;
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}
*/
